import _ from 'lodash';
import { NextPage } from 'next';
import { LogoJsonLd, OrganizationJsonLd, SocialProfileJsonLd } from 'next-seo';
import { useRouter } from 'next/router';
import { controller } from 'src/switch-themes';

type Props = {
  data?: any;
  page?: string;
};

const SnippetsApp: NextPage<Props> = () => {
  const { address, socials, website, name, image } = controller;
  const filterSameAs: any = Object.values(socials).filter(el => !_.isEmpty(el));
  const router = useRouter();
  const notShowLists = ['/[cid]/[id]', '/galleries/[id]', '/video/[cid]/[id]'];
  return (
    <>
      <OrganizationJsonLd type='Organization' id={`${website}`} logo={image.logo} legalName={`${name.en}`} name={`${name.en}`} address={{ ...address }} sameAs={[...filterSameAs]} url={`${website}`} />
      <LogoJsonLd logo={image.logo} url={`${website}`} />
      {!notShowLists.includes(router.pathname) ? <>
        <SocialProfileJsonLd type='Organization' name={`${name.en}`} url={`${website}`} sameAs={[...filterSameAs]} />
      </> : null}
    </>
  );
};
export default SnippetsApp;
